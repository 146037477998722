import {About , NavBar , HomePage , FreelancingPage , Portfolio} from '../components' 

const Home = ()=>{
    return (
        <>
            <NavBar />
            <HomePage />
            <About />
            <FreelancingPage />
            <Portfolio />
        </>
    )
 }

 export default Home